

//const URL = process.env.REACT_APP_URL;
const URL = process.env.REACT_APP_URL || "http://localhost:8080";


export async function loginReq(data) {
    const response = await fetch(`${URL}/v1/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
    if(response.status !== 200) {
        return {"status": response.status, "msg": await response.text()}
    }
    return await response.json();
}
export async function sendSmsReq(phone) {
    const response = await fetch(`${URL}/v1/smscode`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({phone})
    });
    if(response.status !== 200) {
        return {"status": response.status, "msg": await response.text()}
    }
    return await response.json();
}

export  function authReq(token) {
    let request = new XMLHttpRequest();
    request.open("GET", `${URL}/v1/auth`, false);  // `false` makes the request synchronous
    request.setRequestHeader('Authorization', token);
    request.send();
    if (request.status === 200) {
        return true;
    } else {
        return false;
    }
}