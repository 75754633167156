import {useCallback, useEffect, useState} from "react";
import {getOrders} from "../api/orders";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import MiniSlog from "../components/MiniSlog";

const OSS = require('ali-oss');
const KEY_ID = process.env.REACT_APP_KEY;
const SECRET = process.env.REACT_APP_SECRET;

const client = new OSS({
    accessKeyId: KEY_ID,
    accessKeySecret: SECRET,
    region: 'oss-cn-beijing',
    bucket: 'selfies'
});


export function  MyPhotos() {
    const [photos, setPhotos] = useState([])
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const presentPhotos = async () => {
        const orders = await getOrders()
        const xueliPhotos = await  Promise.all(orders.orders.map(async (order) => {
            if(order.xueliPhotos && order.xueliPhotos != "") {
                const xuelis = await Promise.all(JSON.parse(order.xueliPhotos).map((url) => client.get(url)))
                const uids = JSON.parse(order.xueliPhotos)
                const xueliPhotos = uids.map((uid, ind) => ({
                    uid,
                    src: xuelis[ind].content.toString(),
                    height: 3,
                    width: 4,
                }))
                return xueliPhotos
            } else {
                return []
            }
        }))
        return xueliPhotos.flat()
    }
    useEffect(() => {
        presentPhotos().then((photos) => {
            setPhotos(photos)
        })
    }, [])

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <div>
            <div style={{padding: "5%"}}>
            <Gallery photos={photos} onClick={openLightbox} margin={8}/>
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={photos.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
        </div>
        )
}