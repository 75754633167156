import "./Login.css"
import {LoginModal} from "../components/LoginModal";
import {useDispatch} from "react-redux";
import {login as loginAction} from "../features/AuthSlice";


export function Login() {
    const dispatch = useDispatch();
    return (
        <div className="login-container">
            <img className="app-bg"   src="/app/login-bg.png" />
            <img className="browser-bg" src="/browser/login-bg.png" />
            <LoginModal submit={(token, phone) => {dispatch(loginAction({token, phone}))}}/>
        </div>
    )
}