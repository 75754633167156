import Store from "../Store";

const URL = process.env.REACT_APP_URL || "http://localhost:8080";

export async function aliPay(data) {
    const state = Store.getState();
    const token = state.auth.token;
    const response = await fetch(`${URL}/v1/alipay`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token,
        },
        body: JSON.stringify(data)
    });
    if(response.status !== 200) {
        throw new Error(await response.text())
    }
    return await response.json();
}
export async function paymentStatus(id) {
    const state = Store.getState();
    const token = state.auth.token;
    const response = await fetch(`${URL}/v1/alipay/status?tradeNo=${id}`, {
        headers: {
            "Authorization": token,
        },
    });
    if(response.status !== 200) {
        throw new Error(await response.text())
    }
    return await response.json();
}