import { createSlice } from "@reduxjs/toolkit"

const loadState = () => {
    try {
        const serializedState = localStorage.getItem("auth");

        if (serializedState === null ||  serializedState === undefined || serializedState === "undefined") {
            return {token: "", logined: false};
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};
const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("auth", serializedState);
    } catch {
    }
};
export const authSlice = createSlice({
    name: "auth",
    initialState: loadState(),
    reducers: {
        login: (state, {payload}) => {
            const {token, phone} = payload
            state.token = token;
            state.logined = true;
            state.phone = phone;
            saveState(state);
            localStorage.setItem("token", token);
            window.location.href = "/";

        },
        logout: (state) => {
            state.token = "";
            state.logined = false;
            saveState(state);
            localStorage.setItem("token", "");
            window.location.href = "/";
        }
    }
})

export const { login,  logout} = authSlice.actions

export default authSlice.reducer
