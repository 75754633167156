import './Main.css';
import {Fragment, useEffect} from "react";
import {useDispatch} from "react-redux";
import {reset} from "../features/NewOrderSlice";
import MiniSlog from "../components/MiniSlog";



export  default function Main() {
	    const dispatch = useDispatch();
	    const submitPhoto = () => window.location.href  = "/orders"
	    const downloadPhotos = () => window.location.href = "/myphotos"
	    const plusPhoto  = () => window.location.href = "/myorders?filter=plusable"
	    dispatch(reset());
	    useEffect(() => {
		            const header = document.querySelector(".span")
		            header.style.backgroundImage = "unset"
		        }, [])
	    return (
		            <Fragment>
		                <div className="slog">
		                    <div className="content">
		                        <h1>我们</h1>
		                        <p>致力于高校毕业生照片搜集制作管理工作，</p>
		                        <p>为毕业生提供便捷，高效，安全的图像信息拍摄上传平台。</p>
		                    </div>
		                </div>
		                <div className="tabs">
		                    <div className="item-wrapper">
		                        <div className="tab-item-browser" onClick={submitPhoto}>
		                            <img src="/browser/提交照片.png"/>
		                        </div>
		                        <div className="tab-item-app" style={{
						                        backgroundImage: "linear-gradient(135deg, #F18F60 0%, #ED6D46 100%)",
							                    }} onClick={submitPhoto}>
		                            <img src="/app/递交照片.png" />
		                            <span>提交照片</span>
		                        </div>
		                        <div className="tab-item-browser" onClick={plusPhoto}>
		                            <img src="/browser/照片加洗.png"/>
		                        </div>

		                        <div className="tab-item-app" style={{
						                        backgroundImage: "linear-gradient(135deg, #87C0CA 0%, #108B96 100%)",
							                    }} onClick={plusPhoto}>
		                            <img src="/app/加洗照片.png" />
		                            <span>加洗照片</span>
		                        </div>
		                        <div className="tab-item-browser" onClick={downloadPhotos}>
		                            <img src="/browser/照片下载.png"/>
		                        </div>
		                        <div className="tab-item-app" style={{
						                        backgroundImage: "linear-gradient(135deg, #C3D94E 0%, #84A729 100%)"
						                    }} onClick={downloadPhotos}>
		                            <img src="/app/照片下载.png" />
		                            <span>照片下载</span>
		                        </div>
		                        <div className="tab-item-browser">
		                            <img src="/browser/公众号.png"/>
		                        </div>
		                        <div className="tab-item-app" style={{
						                        backgroundImage: "linear-gradient(135deg, #106898 0%, #003460 100%)"
						                    }}>
		                            <img src="/app/公众号.png" />
		                            <span>公众号</span>
		                        </div>
		                    </div>
		                </div>
		            </Fragment>
		        );
}


