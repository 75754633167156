import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router,  Switch} from "react-router-dom";
import {Provider} from "react-redux";
import Store from "./Store";
import {Login} from "./pages/Login";

function Index() {
    return (
        <React.StrictMode>
            <Provider store={Store}>
            <Router>
                    <Switch>
                        <Router exact path="/login">
                            <Login />
                        </Router>
                        <App />
                    </Switch>
            </Router>
            </Provider>
        </React.StrictMode>
    )
}
ReactDOM.render(
    <Index />,
document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
