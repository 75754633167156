import "./PhotoUpload.css"
import {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setImage, setReadRequirement} from "../features/NewOrderSlice";
import Webcam from "react-webcam";
import {Button, message, Modal, notification} from "antd";
import {isMobile} from "react-device-detect";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import imageCompression from "browser-image-compression";
import {checkPhoto} from "../api/orders";

const OSS = require('ali-oss');
const KEY_ID = process.env.REACT_APP_KEY;
const SECRET = process.env.REACT_APP_SECRET;

const client = new OSS({
	    accessKeyId: KEY_ID,
	    accessKeySecret: SECRET,
	    region: 'oss-cn-beijing',
	    bucket: 'selfies'
});
const toBase64 = file => new Promise((resolve, reject) => {
	    const reader = new FileReader();
	    reader.readAsDataURL(file);
	    reader.onload = () => resolve(reader.result);
	    reader.onerror = error => reject(error);
});

export function PhotoUpload({img, confirmImage, allowCamera}) {
	    const [imgSrc, setImgSrc] = useState(img);
	    const dispatch = useDispatch();
	    const inputRef = useRef();
	    const countDownRef = useRef();
	    const [showCrop, setShowCrop] = useState(false);
	    const [cropImgSrc, setCropImgSrc] = useState();
	    const readRequirement = useSelector(state => state.newOrder.readRequirement)
	    const bizSerialNum = useSelector(state => state.newOrder.orderInfo.bizSerialNum)
	    const xh = useSelector(state => state.newOrder.orderInfo.xh)

	    const [takeCamera, setTakeCamera] = useState(false);
	    const [cameraSize, setCameraSize] =  useState({width: 480, height: 640});
	    const [countDown, setCountDown] = useState(10);
	    const [uploadedImageId, setUpLoadedImageId] = useState();
	    const [croppedImageSrc, setCroppedImageSrc] = useState();
	    const [videoOption, setVideoOption] = useState({
		            height: 640,
		            width: 480});
	    const [faceMode, setFaceMode] = useState(true);
	    const onFileChange = async (event) =>  {
		            let img = new Image()
		            img.src = window.URL.createObjectURL(event.target.files[0])
		            img.onload = async () => {
				                if(img.width < 480 || img.height < 640) {
							                notification.error({
										                    description: "图片长宽不满足要求，请重新上传"
										                })
							            } else {
									                    setUpLoadedImageId(`${Date.now().toString()}-${event.target.files[0].size.toString()}`)
									                    const imageSrc = await toBase64(event.target.files[0]);
									                    setCropImgSrc(imageSrc);
									                    setShowCrop(true);
									                    event.target.value = null;
									                }
				            }

		        };
	    const cropperRef = useRef(null);

	    const webcamRef = useRef(null);
	    const capture = useCallback(
		            async () => {
				                const imageSrc = webcamRef.current.getScreenshot();
				                setCropImgSrc(imageSrc)
				                let imgId = `${Date.now().toString()}-${Math.ceil(Math.random() * 10000)}`
				                setUpLoadedImageId(imgId)
				                setShowCrop(true);
				            },
		            [webcamRef]
		        );
	    useEffect(() => {
		            let timer = setInterval( () => {
				                 setCountDown(prev => {
							                  if(prev > 0) {
										                       return prev - 1
										                   } else {
													                        clearInterval(timer)
													                        countDownRef.current && countDownRef.current.classList.remove("disabled-count")
													                        countDownRef.current && countDownRef.current.addEventListener("click", () => {
																	                         dispatch(setReadRequirement(false))
																	                     })
													                        return prev
													                    }
							              })
				            }, 1000)
		            return () => clearInterval(timer)
		        }, [])
	    useLayoutEffect(() => {
		            if(isMobile) setCameraSize({height: 450, width: 340});
		            if(isMobile) setVideoOption({});
		        }, [])


	    return (
		            <div className="photo-upload-container">
		                <input ref={inputRef} style={{display: "none"}} accept="image/*" type="file" onChange={onFileChange} />
		                <Modal
		                    visible={showCrop}
		                    cancelText={"取消"}
		                    okText={"确定"}
		                    bodyStyle={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center"}}
		                    closeIcon={<div/>}
		                    footer={[
					                        <Button className="cbtn" onClick={() => setShowCrop(false)}>取消</Button>,
					                        <Button className="cbtn" onClick={async () => {
									                        const imageElement = cropperRef && cropperRef.current;
									                        const cropper = imageElement && imageElement.cropper;
									                        if(cropper) {
													                          const croppedImageSrc = cropper.getCroppedCanvas().toDataURL()
													                          await client.put(uploadedImageId, new OSS.Buffer(croppedImageSrc));
													                          dispatch(setImage({imageSrc: croppedImageSrc, imageURL: uploadedImageId}))
													                          setImgSrc(croppedImageSrc);
													                          setShowCrop(false);
													                          confirmImage();
													                        }
									                    }}>确认</Button>
					                    ]}
		                    destroyOnClose={true}
		                    maskClosable={true}
		                >
		                    <Cropper
		                        src={cropImgSrc}
		                        background={false}
		                        autoCropArea={0.65}
		                        cropBoxMovable={true}
		                        dragMode={"none"}
		                        cropBoxResizable={false}
		                        restore={false}
		                        guides={false}
		                        center={false}
		                        data={{
						                        width: 480,
							                        height: 640,
							                        center: true,
							                    }}
		                        ref={cropperRef}
		                    />
		                </Modal>
		                <Modal
		                    visible={takeCamera}
		                    cancelText={"取消"}
		                    okText={"确定"}
		                    bodyStyle={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center"}}
		                    closeIcon={<div/>}
		                    footer={[
					                         <Button className="cbtn" onClick={() => setTakeCamera(false)}>取消</Button>,
					                        isMobile ? <img src="/switch.svg"
					                                        onClick={() => setFaceMode(prev => !prev) }
					                                  style={{cursor: "pointer"}}/> : <></>,
					                         <Button className="cbtn" onClick={() => {
									                          capture();
									                          setTakeCamera(false);
									                      }}>确认</Button>
					                    ]}
		                    destroyOnClose={true}
		                    maskClosable={true}
		                >
		                    <img src="outline.svg" className={"outline"}/>
		                    {faceMode ?
					                    <Webcam
					                    audio={false}
					                    height={cameraSize.height}
					                    className={"camera"}
					                    mirrored={false}
					                    ref={webcamRef}
					                    screenshotFormat="image/jpeg"
					                    width={cameraSize.width}
					                    videoConstraints={Object.assign({
								                        facingMode: "user"
								                    }, videoOption)} /> : <Webcam
					                            audio={false}
					                            height={cameraSize.height}
					                            ref={webcamRef}
					                            className={"camera"}
					                            screenshotFormat="image/jpeg"
					                            width={cameraSize.width}
					                            videoConstraints={Object.assign({
									                                facingMode: { exact: "environment" }
									                            }, videoOption)} />}
		                    </Modal>
		                    <div className="upload-info">
		                        <img src={imgSrc} className={"upload-demo"}  />
		                        <div className={"upload-description"}>
		                            <div className="upload-hint">
		                                {true ? <>
							                        <h1>照片要求：</h1>
							                        <hr />
							                        <div>
							                       <span>像素：宽480*高640，</span>
							                       <span>分辨率300dpi，</span>
							                       <span>24位真彩色，</span>
							                       <div/>
							                       <span>文件大小20kb-40kb，</span>
							                       <span>文件格式JPG；</span>

							                        <div style={{marginTop: 20}}/>
							                            <span>人像水平居中，左右对称，</span>
							                            <span>按图示数据保留人与图像边框距离。</span>
							                        </div>
							                            </> : <>
							                            <h1>拍照提示：</h1>
							                            <hr />
							                                <span>1. 使用手机拍照时，请调用后置摄像头拍摄；</span>
							                                <span>2. 请选用纯蓝色背景，面部光线确保均匀充足；</span>
							                                <span>3. 出镜人头部居中，正对镜头，露出眉毛和耳朵，面部无遮挡；</span>
							                                <span>4. 请着装大方得体，切忌妆造过度。</span>
							                            </>}
		                         </div>
		                        <div className="upload-btns">
		                          {readRequirement ?
						                        <>
						                            <div ref={countDownRef} className="count-down-btn disabled-count" >
						                                我已阅读 ({countDown}s)
						                            </div>
						                        </>   :
						                        <>
						                        {allowCamera && <img src="take-camera.svg" onClick={() => setTakeCamera(true)}/> }
						                         <img src="upload-image.svg"  onClick={() => inputRef.current.click()}/>
						                         </>}
		                        </div>
		                        </div>
		                    </div>
		            </div>
		        )
}
