import Store from "../Store";

const URL = process.env.REACT_APP_URL || "http://localhost:8080";

export async function getOrders() {
    const state = Store.getState();
    const token = state.auth.token;
    const response = await fetch(`${URL}/v1/myorders`, {
        headers: {
            "Authorization": token,
        },
    });
    if(response.status !== 200) {
        throw new Error(await response.text())
    }
    return await response.json();
}
export async function updatePhoto(data) {
    const state = Store.getState()
    const token  = state.auth.token;
    const response = await fetch(`${URL}/v1/order/updatePhoto`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token,
        },
        body: JSON.stringify(data)
    });
    if(response.status !== 200) {
        throw new Error(await response.text())
    }
    return await response.json();
}
export async function getUserInfo(id, name) {
    const state = Store.getState();
    const token = state.auth.token;
    const response = await fetch(`${URL}/v1/userinfo/${id}/${name}`, {
        headers: {
            "Authorization": token,
        },
    });
    return response
}

export async function verifyPush(mark) {
    const state = Store.getState();
    const token = state.auth.token;
    const response = await fetch(`${URL}/v1/xuexin/${mark}`,{
        headers: {
            "Authorization": token,
        },
    })
    if(response.status === 200) {
        const result = await response.json()
        if(result.custRemarks === mark) return result
        throw new Error(result.toString())
    }
}

export async function checkPhoto(qrcodeSerialNum, xh, photo) {
    const response = await fetch("https://hbjtsd.com/photo-verify", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({qrcodeSerialNum, xh, photo})
    })
    return await response.json()
}

export async function getQrCode(mark) {
    const response = await fetch(`https://hbjtsd.com/qrcode/${mark}`)
    return await response.json()
}
export async function getSchools() {
    const state = Store.getState();
    const token = state.auth.token;
    const response = await fetch(`${URL}/v1/admin/schools/lister`, {
        headers: {
            "Authorization": token,
        },
    });
    if(response.status !== 200) {
        throw new Error(await response.text())
    }
    return await response.json();
}
export async function addOrder(data) {
    const state = Store.getState();
    const token = state.auth.token;
    const response = await fetch(`${URL}/v1/orders`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token,
        },
        body: JSON.stringify(data)
    });
    if(response.status !== 200) {
        throw new Error(await response.text())
    }
    return await response.json();
}