import "./LoginModal.css"
import {useForm} from "react-hook-form";
import {useRef, useState} from "react";
import {loginReq, sendSmsReq} from "../api/users";

export function LoginModal(props) {
    const { register, getValues, trigger, handleSubmit, formState: { errors } } = useForm();
    const [codeContent, setCodeContent] = useState("获取验证码");
    const formRef = useRef();
    const errorRef = useRef();
    const popupRef = useRef();
    const onSubmit = async (data) => {
        let res = await loginReq(data);
        if(res.status === "ok") {
            props.submit(res.token, data.phone);
        } else {
            //login error happened
            errorRef.current.classList.add("show")
            setTimeout(() =>   errorRef.current.classList.remove("show"), 1000);
            console.log(res.msg);
        }
    }

    const sendSmsCode = async (event) => {
        if(codeContent !== "获取验证码" ) {
            return;
        }
        const valid = await trigger(["phone"])
        if(!valid) {
            return;
        }
        const res = await sendSmsReq(getValues("phone"));
        if(res.status !== "ok") {
            console.log(res);
            alert(res.msg);
            return
        }
        event.target.classList.add("disabled-sendcode");
        setCodeContent(60);
        popupRef.current.classList.add("show");
        setTimeout(() =>   popupRef.current.classList.remove("show"), 1000);
        let timer = setInterval(() => {
            setCodeContent((prev)  => {
                if(prev > 0) {
                    return prev - 1;
                } else {
                    clearInterval(timer);
                    event.target.classList.remove("disabled-sendcode");
                    return  "获取验证码";
                }
            })
        }, 1000);
    }
    return (
        <form  ref={formRef} className="login-modal-container"
               onSubmit={handleSubmit(onSubmit)}>
            <img ref={popupRef}  className="hide" src="/登陆注册提示.png" style={{position:"absolute", top: -100}} />
            <img ref={errorRef}  className="hide" src="/验证码错误.png" style={{position:"absolute", top: -100}} />
            <div className="login-cap">
                登录
            </div>
            <div className="control-group">
                <input type="tel" name="phone" placeholder="手机号"
                       {...register("phone", {
                           required: "手机号不能为空",
                           minLength: {
                               value: 11,
                               message: "手机号长度不对",
                           },
                           maxLength: {
                               value: 11,
                               message: "手机号长度不对",
                           },
                           pattern: {
                               value: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
                               message: "手机号格式不对"
                           }})} />
            </div>
            {errors.phone && <div className="err control-group">{errors.phone.message}</div>}
            <div className="control-group smscode-group">
              <div className="control-group smscode">
                <input type="text" name="smscode" placeholder="短信验证码"
                       {...register("smscode", {required: "验证码不能为空 "})} />
              </div>
              <div className="control-group sendcode " onClick={sendSmsCode}>
                  {codeContent}
              </div>
            </div>
            {errors.smscode && <div className="err control-group">{errors.smscode.message}</div>}
            <input type="submit"  className="control-group login-btn" />
        </form>
    )
}