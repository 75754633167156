import {useEffect, useState} from "react";
import MiniSlog from "../components/MiniSlog";
import {useDispatch, useSelector} from "react-redux";
import "./SubmitOrder.css";
import {
	    enableCamera,
	    setActiveStage,
	    setFinishStage,
	    setMark,
	    setOrderInfo
} from "../features/NewOrderSlice";
import {useForm} from "react-hook-form";
import {aliPay, paymentStatus} from "../api/payment";
import 'antd/dist/antd.css';
import {Modal, Select, notification} from 'antd';
import {addOrder, getSchools, getUserInfo, verifyPush} from "../api/orders";
import {useHistory} from "react-router-dom";
import {isMobile} from 'react-device-detect';
import {PhotoUpload} from "./PhotoUpload";
const { Option } = Select;


function errorPaid() {
	    Modal.error({
		            content: "支付失败请重试!",
		        });
}
function infoPaid() {
	    Modal.info({
		            content: "订单已关闭!",
		        });
}


export  default  function SubmitOrder() {
	    const history = useHistory();
	    const { register, handleSubmit, trigger, formState: { errors } } = useForm();
	    const stage = useSelector(state => state.newOrder.stage)
	    const sourceType = useSelector(state => state.newOrder.sourceType)
	    const finishedStage = useSelector(state => state.newOrder.finishedStage)
	    const orderInfo = useSelector(state => state.newOrder.orderInfo)
	    const phone  = useSelector(state => state.auth.phone)
	    const imageURL = useSelector(state => state.newOrder.imageURL)
	    const imageSrc = useSelector(state => state.newOrder.imageSrc)
	    const allowCamera = useSelector(state => state.newOrder.allowCamera)
	    const dispatch = useDispatch()
	    const [_, setPaymentStatusTimer] = useState()
	    const [countDown, setCountDown] = useState(3)
	    const [payModal, setPayModal] = useState(false)
	    const [payURL, setPayURL] = useState("")
	    const [schoolCodes, setSchoolCodes] = useState([])


	    useEffect(() => {
		            getSchools().then((res) => {
				                setSchoolCodes(res.data.map(o => `${o.code} | ${o.name}`))
				            }).catch(err => console.log(err))
		        }, [])

	    const nextTab = (ind) => {
		            if(finishedStage >= ind - 1) { dispatch(setActiveStage(ind))}
		        }

	    console.log(orderInfo)

	    const updateOrderInfo = (e) => {
		            dispatch(setOrderInfo({[e.target.name]: e.target.value}))
		        }
	    const submitOrderInfo = async () => {
		            const result = await trigger(["name", "zjNumber"]);
		            if(!result) {
				                return
				            }
		            if(!orderInfo.school_code) {
				                errors.schoolCode = {message: "学校不得为空"}
				                return
				            }
		            delete errors.schoolCode
		            const res = await getUserInfo(orderInfo.zjNumber, orderInfo.name)
		            if(res.status === 404) {
				                notification.error({
							                description: "无法匹配学生信息，请重新录入或与所在学校联系"
							            })
				                return
				            }
		            if(res.status === 403) {
				                notification.error({
							                description: "您有未审核完毕的订单，请勿重复提交订单"
							            })
				                return
				            }
		            const userInfo = await res.json()
		            if(userInfo.school_code.trim() !== orderInfo.school_code.trim() || userInfo.school_name.trim() !== orderInfo.school_name.trim()) {
				                notification.error({
							                description: "无法匹配学生信息，请重新录入或与所在学校联系"
							            })
				                return 
				            }
		            if(userInfo.allow_camera === 1) {
				                dispatch(enableCamera())
				            }
		            userInfo.phone = phone
		            dispatch(setOrderInfo(userInfo))
		            dispatch(setFinishStage(stage));
		            dispatch(setActiveStage(stage + 1));
		        }
	    const confirmPayment = async () => {
		            try {
				                let reqParams =  Object.assign({}, JSON.parse(JSON.stringify(orderInfo)));
				                if(imageURL === "") {
							                notification.error({
										                    "message": "必须上传图片"
										                })
							                return
							            }
				                reqParams.orderSource = sourceType === "加洗" ? sourceType : "采集";
				                reqParams.imageURL = imageURL;
				                if(isMobile) reqParams.device = "mobile"
				                let orderId = orderInfo.orderId
				                if(!orderId) {
							                let createOrderRes = await addOrder(reqParams);
							                dispatch(setOrderInfo({orderId: createOrderRes.orderId}))
							                orderId = createOrderRes.orderId
							            }
				                if(reqParams.prepaid === 1) {
							                dispatch(setFinishStage(stage))
							                dispatch(setActiveStage(stage + 1))
							                let countdown = setInterval(() => {
										                    setCountDown(prev => {
													                            if (prev === 0) {
																	                                clearInterval(countdown)
																	                                window.location.href = "/"
																	                                return 0
																	                            } else {
																					                                return prev - 1
																					                            }
													                        })
										                }, 1000);
							                return
							            }
				                const res = await aliPay({
							                "subject": "教图时代照片打印服务",
							                "orderId": orderId,
							                "sourceType": sourceType === "加洗" ? sourceType : "采集",
							            });
				                const paymentURL = `${res.url.Scheme}://${res.url.Host}${res.url.Path}?${res.url.RawQuery}`;
				                const tradeNo = res.tradeNo;
				                setPayURL(paymentURL);
				                setPayModal(true);
				                let timer = setInterval(async () => {
							                try {
										                    let respStatus = await paymentStatus(tradeNo)
										                    if (respStatus.status === "SUCCESS") {
													                            setPaymentStatusTimer((prev) => clearInterval(prev))
													                            setPayModal(false);
													                            dispatch(setFinishStage(stage))
													                            dispatch(setActiveStage(stage + 1))
													                            let countdown = setInterval(() => {
																	                                    setCountDown(prev => {
																						                                        if (prev === 0) {
																												                                        clearInterval(countdown)
																												                                        window.location.href = "/"
																												                                        return 0
																												                                    } else {
																																	                                            return prev - 1
																																	                                        }
																						                                    })
																	                               }, 1000);
													                        } else if (respStatus.status === "FAILED") {
																	                        setPaymentStatusTimer((prev) => clearInterval(prev))
																	                        errorPaid();
																	                    } else if (respStatus.status === "CLOSED") {
																				                            setPaymentStatusTimer((prev) => clearInterval(prev))
																				                            infoPaid();
																				                        }
										                } catch (e) {
													                    errorPaid(() => setPaymentStatusTimer((prev) => clearInterval(prev)))
													                }
							            }, 1000)
				                setPaymentStatusTimer((prev) => {
							                clearInterval(prev);
							                return timer;
							            });
				            } catch (e) {
						                console.log(e);
						                errorPaid();
						            }

		        }
	    return (
		            <form>
		                {payModal &&
					            <>
					            <div className="pay-overlay"/>
					            <div className="pay-popup">
					                <iframe src={payURL}  width="400" height="600"/>
					            </div> </>}
		                <div className="order-wrapper">
		                    <div className={`order-tab`}>
		                        <ul>
		                            {sourceType === "加洗" ? (
						                            <div className={"nav-plus"}>
						                            <li className={"active plus-order nav-myorder"} onClick={() => history.push("/myorders")}>我的订单</li>
						                            <li className="arrow"> > </li>
						                            <li className={"active plus-order plus-head"}>加洗照片</li>
						                            </div>) : <div className={"nav-plus"}> <li className={"so-head"}> 提交照片 </li></div>}
		                            <div className={"nav-wrapper"}>{["录入信息", "照片上传", "订单确认", "完成订单"].map((t, ind) => {
						                                return (
											                                <li key={ind} className={`${stage === ind ? "active" : "inactive"}
																                                ${(sourceType === "加洗" && t === "录入信息") ? "hide-tab" : ""}
																                               ${(sourceType === "加洗" && t === "照片上传") ? "hide-tab" : ""}
																                                ${sourceType === "加洗" ? "plus-order" : "normal-order"}
																                                `
																                                }
											                                    onClick={() => nextTab(ind)}>{t}</li>
											                            )
						                            })}</div>
		                        </ul>
		                    </div>
		                    <div className={`order-info ${0 === stage ? "" : "hide" }`}>
		                        <div className="control-group">
		                            <div className="label"> 学校名称/代码</div>
		                            <Select
		                                showSearch
		                                size="large"
		                                className="dropdown"
		                                defaultValue={orderInfo.school_code ? `${orderInfo.school_code} | ${orderInfo.school_name}` : ""}
		                                onChange={(code) => {
							                                dispatch(setOrderInfo({school_code: code.split("|")[0], school_name: code.split("|")[1]}))
							                            }}>
		                                {schoolCodes.map(code => <Option value={code}>{code}</Option>)}
		                            </Select>
		                        </div>
		                        {errors.schoolCode && <div className="err control-group">{errors.schoolCode.message}</div>}

		                        <div className="control-group">
		                            <div className="label">证件类型</div>
		                            <Select
		                                className="dropdown"
		                                size="large"
		                                defaultValue={"身份证"}
		                            >
		                                <Option value="身份证">身份证</Option>
		                                <Option value="士官证">士官证</Option>
		                            </Select>
		                        </div>
		                        <div className="control-group">
		                            <div className="label"> 证件号码</div>
		                            <input type="text" name="zjNumber" value={orderInfo.zjNumber}
		                                   {...register("zjNumber", { required: "证件号码不得为空." })}
		                                   onChange={updateOrderInfo} 
		                            />
		                        </div>

		                        {errors.zjNumber && <div className="err control-group">{errors.zjNumber.message}</div>}

		                        <div className="control-group">
		                            <div className="label"> 姓名</div>
		                            <input  type="text" name="name" value={orderInfo.name}
		                                   {...register("name", { required: "姓名不得为空." })}
		                                   onChange={updateOrderInfo}
		                            />
		                        </div>
		                        {errors.name && <div className="err control-group">{errors.name.message}</div>}
		                                      <div className="control-group">
		                            <div id="first-submit" className="order-btn" onClick={submitOrderInfo}>
		                                提交信息
		                            </div>
		                        </div>
		                    </div>
		                    <div className={`submit-photo ${1 === stage ? "" : "hide"}`} >
		                        {1 === stage  &&
						                    <PhotoUpload img={imageSrc || "upload-demo.svg"} allowCamera={allowCamera} confirmImage={() => {
									                            dispatch(setFinishStage(stage));
									                            dispatch(setActiveStage(stage + 1));
									                        }}/>}
		                    </div>
		                    <div className={`confirm-info ${2 === stage ? "" : "hide"} ${sourceType === "加洗" ? "confirm-info-plus" : ""}`} style={{marginLeft: "0px"}}>
		                        <img src={imageSrc} className={"upload-demo"}  />
		                        <div className="control-group confirm-detail"  style={{display: "flex", flexDirection: "column"}}>
		                            <div className="reminder">
		                                姓名：
		                                {sourceType !== "加洗" ?
								                            orderInfo.name : (
												                                    <input type="text" name="name" value={orderInfo.name} style={{display: "inline-block", width: "150px"}}
												                                          onChange={updateOrderInfo}
												                                    />
												                                )}
		                            </div>
		                            <div className="reminder">证件号码：{orderInfo.id_no}</div>
		                            <div className="reminder">电话号码：
		                                {sourceType !== "加洗" ?
								                                orderInfo.phone : (
													                                    <input type="text" name="phone" value={orderInfo.phone} style={{display: "inline-block", width: "150px"}}
													                                           onChange={updateOrderInfo}
													                                    />
													                                )}
		                            </div>
		                            <div className="reminder">学校名称/代码：{orderInfo.school_code} | {orderInfo.school_name}</div>
		                            <div className="reminder">所在校别：{orderInfo.xiaobie}</div>
		                            <div className="reminder">学历类别：{orderInfo.xueli_type}</div>
		                            <div className="reminder">邮寄地址：
		                                {sourceType !== "加洗" ?
								                                orderInfo.address : (
													                                    <textarea  name="phone" value={orderInfo.address} style={{display: "inline-block", height: "120px"}}
													                                           onChange={updateOrderInfo}
													                                    />
													                                )}
		                            </div>
		                            <div className="reminder">订单价格：{orderInfo.prepaid === 1 ? "0.00元（学校已支付）" : "15.0元"}</div>
		                        </div>
		                        <div className="control-group" style={{display: "flex", width: "auto"}}>
		                            {sourceType !== "加洗" && (
						                            <div className="order-btn" onClick={() => dispatch(setActiveStage(stage - 1))} style={{marginLeft: 0}}>
						                                返回修改
						                            </div>)}
		                            <div className="order-btn" onClick={(e) => {
						                                if(e.target.classList.contains("poped")) {
											                                confirmPayment()
											                            } else {
															                                    Modal.info({
																				                                        content: (
																										                                        <p> 订单确认支付后，上传信息及照片将无法修改，请核对无误后确认。</p>
																										                                    ),
																				                                        onOk: () => {
																										                                        e.target.classList.add("poped")
																										                                    },
																				                                    });
															                                }
						                            }
						                            }>
		                                确认支付
		                            </div>
		                        </div>

		                    </div>

		                    <div className={`finish-info ${3 === stage ? "" : "hide"}`} >
		                        <div className="control-group">
		                            <img src="支付成功.png" />
		                            <div className="reminder" style={{marginTop: "20px"}}>支付金额：
		                                ¥ {orderInfo.prepaid === 1 ? "0" : "15.00"}元
		                            </div>
		                            <div className="reminder" style={{fontSize: "24px", width: "300px", marginTop: "20px"}}>
		                                <span style={{color: "red", fontSize: "28px"}}>{countDown}</span> 秒后将自动跳转...
		                            </div>
		                        </div>
		                    </div>
		                    </div>
		            </form>
		        )
}
