import { createSlice } from '@reduxjs/toolkit'

const initState = {
    stage: 0,
    sourceType: "集采",
    finishedStage: -1,
    imageURL: "",
    showQrCodeModal: true,
    mark: "",
    readRequirement: true,
    imageSrc: "",
    orderInfo: {},
    allowCamera: false,
    paymentInfo: {},
};
const loadState = () => {
    try {
        const serializedState = sessionStorage.getItem("order");
        if (serializedState === null ||  serializedState === "undefined" || serializedState === undefined) {
            return initState;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};
const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem("order", serializedState);
    } catch {
    }
};
export const newOrderSlice = createSlice({
    name: 'newOrder',
    initialState: loadState(),
    reducers: {
        setActiveStage: (state, action) => {
            state.stage = action.payload
            saveState(state)
        },
        setFinishStage: (state, action) => {
            state.finishedStage = action.payload
            saveState(state)
        },
        setOrderInfo: (state, action) => {
            state.orderInfo = Object.assign(state.orderInfo, action.payload)
            saveState(state)
        },
        setImage: (state, action) => {
            state = Object.assign(state, action.payload);
            saveState(state)
        },
        enableCamera: (state) => {
            state.allowCamera = true
            saveState(state)
        },
        setReadRequirement: (state, action) => {
            state.readRequirement = action.payload
            saveState(state)
        },
        setReceiverInfo: (state, action) => {
            state.receiverInfo = Object.assign(state.receiverInfo, action.payload)
            saveState(state)
        },
        hideQrCodeModal: (state) => {
           state.showQrCodeModal = false
           saveState(state)
        },
        setPaymentInfo: (state, action) => {
            state.paymentInfo = action.payload
            saveState(state)
        },
        replicate: (state, action) => {
            state = action.payload
            saveState(state)
        },
        setMark: (state, action) => {
            state.mark = action.payload
            saveState(state)
        },
        reset: (state) => {
            state = Object.assign({}, initState);
            saveState(state);
        }
    }
})

export const { enableCamera, setActiveStage, setReadRequirement, setImage, reset,replicate, setMark, setOrderInfo, setFinishStage,  setReceiverInfo, setPaymentInfo, hideQrCodeModal}  = newOrderSlice.actions

export default newOrderSlice.reducer
