import './App.css';
import {
    Switch,
    Route} from "react-router-dom";
import Main from "./pages/Main";
import Logo from "./components/Logo";
import TitleHeader from "./components/TitleHeader";
import SelfCenter from "./components/SelfCenter";
import SubmitOrder from "./pages/SubmitOrder";
import {ProtectedRoute} from "./components/ProtectedRoute";
import {MyOrders} from "./pages/MyOrders";
import {PhotoUpload} from "./pages/PhotoUpload";
import {useEffect} from "react";
import {MyPhotos} from "./pages/MyPhotos";

function App() {
  useEffect(() => {
      let root = document.getElementById("root")
      let footer = document.getElementById("footer")
      if(!footer) {
          footer = `
      <footer class="beian">
      <a href="https://beian.miit.gov.cn/" target="_blank"
         style="padding-right:20px;padding-bottom: 20px;float:right;color:lightgray">鄂ICP备2021015426号</a>
    </footer>`
          root.insertAdjacentHTML("afterend", footer)
      }
  }, [])
  return (
         <div className="app">
               <div className="span">
                      <Logo />
                      <TitleHeader />
                      <SelfCenter />
                </div>
               <Switch>
               <Route exact path="/">
                   <Main />
               </Route>
               <ProtectedRoute path="/orders">
                  <Route exact path="/orders">
                     <SubmitOrder />
                  </Route>
               </ProtectedRoute>
                <ProtectedRoute path="/myphotos">
                       <Route exact path="/myphotos">
                           <MyPhotos />
                       </Route>
                 </ProtectedRoute>
                <ProtectedRoute path="/upload">
                       <Route exact path="/upload">
                           <PhotoUpload/>
                       </Route>
                </ProtectedRoute>
               <ProtectedRoute path="/myorders">
                   <Route exact path="/myorders">
                        <MyOrders />
                   </Route>
                </ProtectedRoute>
             </Switch>
        </div>

  );
}

export default App;
