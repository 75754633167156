
import './SelfCenter.css';
import {
    Link
} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../features/AuthSlice";

export  default  function SelfCenter() {
    const dispatch = useDispatch();
    const logined =  useSelector(state => state.auth.logined)
    const phone = useSelector(state => state.auth.phone)
    return (
         <div className="self-center">
            <ul className="self-center-browser">
                {logined && <span>欢迎您 {phone}</span>}
                {logined ? (<li onClick={() => dispatch(logout())} className="">【退出】</li>)
                    : (<li onClick={() => window.location.href="/login"} className="">登录</li> )}
                <li><Link to="/"> | 首页</Link></li>
                <li><Link to="/myorders"> | 我的订单</Link></li>
             </ul>
             <ul className="self-center-app">
                 <li><Link to="/">  首页</Link></li>
                 <li><Link to="/myorders">  我的订单</Link></li>
                 {logined ? (<li onClick={() => dispatch(logout())} className="">退出</li>)
                     : (<li onClick={() => window.location.href="/login"} className="">登录</li> )}
             </ul>
          </div>

    )
}