import {Table, Space, Upload, Descriptions, notification} from 'antd';

import {useEffect, useRef, useState} from "react";
import {addOrder, getOrders, getUserInfo, updatePhoto} from "../api/orders";
import "./MyOrders.css"
import {replicate, setActiveStage, setFinishStage} from "../features/NewOrderSlice";
import {useDispatch} from "react-redux";
import MiniSlog from "../components/MiniSlog";
import Modal from "antd/es/modal/Modal";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {isMobile} from "react-device-detect";
import {aliPay, paymentStatus} from "../api/payment";
import {PhotoUpload} from "./PhotoUpload";
import Cropper from "react-cropper";
const OSS = require('ali-oss');
const KEY_ID = process.env.REACT_APP_KEY;
const SECRET = process.env.REACT_APP_SECRET;

const client = new OSS({
	    accessKeyId: KEY_ID,
	    accessKeySecret: SECRET,
	    region: 'oss-cn-beijing',
	    bucket: 'selfies'
});

const toBase64 = file => new Promise((resolve, reject) => {
	    const reader = new FileReader();
	    reader.readAsDataURL(file);
	    reader.onload = () => resolve(reader.result);
	    reader.onerror = error => reject(error);
});

function errorPaid() {
	    Modal.error({
		            content: "支付失败请重试!",
		        });
}
function closePaid() {
	    Modal.info({
		            content: "订单已关闭!",
		        });
}

function infoPaid() {
	    Modal.info({
		            content: "支付成功!",
		        });
}


export  function  MyOrders() {
	    const [data, setData] = useState([]);
	    const [showDetail, setShowDetail] = useState(false);
	    const [orders, setOrders] = useState();
	    const [orderDetail, setOrderDetail] = useState({})
	    const [showUpdatePhotoModal, setShowUpdatePhotoModal] = useState(false)
	    const [updateImage, setUpdateImage] = useState(false)
	    const dispatch = useDispatch()
	    const [payModal, setPayModal] = useState(false)
	    const [payURL, setPayURL] =  useState()
	    const urlParam = new URLSearchParams(window.location.search);
	    const showOnlyPlusableOrders = urlParam.get("filter") === "plusable";
	    const cropperRef = useRef(null);
	    const [showCrop, setShowCrop] = useState(false);
	    const [cropImgSrc, setCropImgSrc] = useState();
	    const showOrderDetail = (o) => {
		            setShowDetail(true)
		            setOrderDetail(o)
		        }

	    const plusOrder = async (orderDetail) => {
		            const res = await getUserInfo(orderDetail.id_no, orderDetail.name)
		            if(res.status === 403) {
				                notification.error({
							                description: "您有未审核完毕的订单，请勿重复提交订单"
							            })
				                return
				            }
		            orderDetail.prepaid = 0
		            orderDetail.orderId = null
		            dispatch(replicate(JSON.parse(JSON.stringify(Object.assign({
				                stage: 2,
				                finishedStage: 1,
				                sourceType: "加洗",
				            }, {
						                orderInfo: {...orderDetail},
						                imageURL: orderDetail.imageURL,
						                imageSrc: orderDetail.imageSrc,
						            })))))
		            window.location.href = "/orders"
		        }
	    const columns = [
		            {
				                title: "类别",
				                dataIndex: "orderType",
				                key: "orderType",
				            },
		            {
				                title: "编号",
				                dataIndex: "orderId",
				                key: "orderId",
				            },
		            {
				                title: "创建时间",
				                dataIndex: "createTime",
				                key: "createTime",
				            },
		            {
				                title: "状态",
				                dataIndex: "status",
				                key: "status",
				            },
		            {
				                title: "详情",
				                dataIndex:  "orderDetail",
				                key: "orderDetail",
				                render: (text, record) => (
							                 <Space size="middle">
							                    <a onClick={async () => {
										                            const res = await client.get(orders[record.key].imageURL);
										                            orders[record.key].imageSrc = res.content.toString();
										                            showOrderDetail(orders[record.key])
										                        }}>{ record.status === "照片审核失败，请重新上传" ? "重新上传照片" : record.status === "未支付" ? "去支付" : "查看明细" }</a>
							                 </Space>
							            )

				            },
		            {
				                title: "金额",
				                dataIndex:  "price",
				                key: "price"
				            },
		            {
				                title: "加洗照片",
				                dataIndex:  "plus",
				                key: "plus"
				            }
		        ];
	    const confirmPayment = async () => {
		            try {
				                const res = await aliPay({
							                "subject": "教图时代照片打印服务",
							                "orderId": orderDetail.orderId,
							                "sourceType": orderDetail.orderSource
							            });
				                const paymentURL = `${res.url.Scheme}://${res.url.Host}${res.url.Path}?${res.url.RawQuery}`
				                const tradeNo = res.tradeNo
				                setPayURL(paymentURL)
				                setPayModal(true);
				                let timer = setInterval(async () => {
							                try {
										                    let respStatus = await paymentStatus(tradeNo)
										                    if (respStatus.status === "SUCCESS") {
													                            clearInterval(timer)
													                            setPayModal(false);
													                            infoPaid();
													                            setTimeout( () => window.location.href = "/myorders", 1000);
													                        } else if (respStatus.status === "FAILED") {
																	                        clearInterval(timer)
																	                        setPayModal(false);
																	                        errorPaid();
																	                    } else if (respStatus.status === "CLOSED") {
																				                            clearInterval(timer)
																				                            setPayModal(false);
																				                            closePaid();
																				                        }
										                } catch (e) {
													                    clearInterval(timer)
													                    errorPaid();
													                }
							            }, 1000)
				            } catch (e) {
						                errorPaid();
						            }
		        }
	    const getStatus = (status) => {
		            if(status === "pending") {
				                return "未支付"
				            } else if (status === "paid") {
						                return  "已支付待审核"
						            } else if (status === "checked") {
								                return "已审核待发货"
								            } else if(status === "delivered") {
										                return "已发货"
										            } else if(status  === "finished") {
												                return "已完成"
												            } else if(status === "rejected") {
														                return "照片审核失败，请重新上传"
														            }
		        }
	    useEffect(() => {
		            getOrders().then((res) => {
				                setOrders(res.orders);
				                let data = res.orders.map((order, ind) => {
							                return {
										                    key: ind,
										                    orderType: order.orderSource,
										                    orderId: order.orderId,
										                    createTime: (new Date(order.createTime)).toLocaleString(),
										                    status: getStatus(order.status),
										                    price: `¥${order.price}`,
										                    plus: (order.status === "delivered" || order.status === "checked" || order.status === "finished") ? "可加洗" : "不能加洗"
										                }
							            });
				                if(showOnlyPlusableOrders) {
							                data = data.filter((order) => order.plus === "可加洗")
							            }
				                setData(data);
				            }).catch((err) => console.log(err))
		        }, [showOnlyPlusableOrders])
	    return (
		            <div className="myorders">
		             {payModal &&
				                 <>
				                     <div className="pay-overlay"/>
				                     <div className="pay-popup">
				                         <iframe src={payURL}  width="400" height="600"/>
				                     </div> </>}
		             <div className={`browser-myorders ${showDetail ? "hide-order-list" : ""}`}>
		                <Table  columns={columns} dataSource={data} />
		             </div>
		             <Modal
		                 visible={showUpdatePhotoModal}
		                 okText={"确认"}
		                 cancelText={"取消"}
		                 destroyOnClose={true}
		                 onCancel={() => {
					                  setShowUpdatePhotoModal(false)
					                  setShowCrop(false)
					              }}
		                 onOk={async () => {
					                  const imageElement = cropperRef && cropperRef.current;
					                  const cropper = imageElement && imageElement.cropper;

					                  if(cropper) {
								                       setUpdateImage(true)
								                       await client.put(orderDetail.imageURL, new OSS.Buffer(cropper && cropper.getCroppedCanvas().toDataURL()));
								                       await updatePhoto({"orderId": orderDetail.orderId});

								                       setTimeout(() => {
											                                notification.success({
																                             description: "更新照片成功"
																                         })
											                                setTimeout(() => {
																                             setShowUpdatePhotoModal(false)
																                             setShowCrop(false)
																                             window.location.reload()
																                         }, 500)
											                            }, 500)
								                       setUpdateImage(false);
								                   }

					              }}
		             >
		                <Descriptions column={2}>
		                    <Descriptions.Item label="替换照片" span={2}>
		                        <Upload
		                            name="avatar"
		                            listType="picture-card"
		                            className="avatar-uploader"
		                            showUploadList={false}
		                            action={ async (file) => {
						                                let img = new Image()
						                                img.src = window.URL.createObjectURL(file)
						                                img.onload = async () => {
											                                if(img.width < 480 || img.height < 640) {
																                                   notification.error({
																					                                          description: "图片长宽不满足要求，请重新上传"
																					                                      })
																                                } else {
																					                                    const bf = await toBase64(file)
																					                                    setCropImgSrc(bf)
																					                                    setShowCrop(true)
																					                                }
											                            }

						                            }}
		                        >
		                            {updateImage ? <LoadingOutlined /> : <PlusOutlined />}
		                        </Upload>
		                    </Descriptions.Item>
		                    <Descriptions.Item label={"裁剪照片"} span={2}>
		                        {showCrop &&
						                    <Cropper
						                        src={cropImgSrc}
						                        background={false}
						                        autoCropArea={0.65}
						                        cropBoxResizable={false}
						                        cropBoxMovable={true}
						                        dragMode={"none"}
						                        restore={false}
						                        guides={false}
						                        center={false}
						                        data={{
										                            width: 480,
											                            height: 640,
											                            center: true,
											                        }}
						                        ref={cropperRef}
						                    />}
		                    </Descriptions.Item>
		                </Descriptions>
		             </Modal>
		             <div className={`app-myorders ${showDetail ? "hide-order-list" : ""}`}>
		                  {data.map((order, ind) => {
					                    return (
								                          <div className="app-order" key={ind}>
								                                <div className="order-header">
								                                    <div className="order-type">{order.orderType}订单</div>
								                                    <div className="order-extra">
								                                        <div className="order-price">{order.price}</div>
								                                    </div>
								                                </div>
								                                <div className="order-content">
								                                    <div>
								                                       <div className="order-label">订单编号</div> {order.orderId}
								                                    </div>
								                                    <div>
								                                       <div className="order-label">创建时间</div> {order.createTime}
								                                    </div>
								                                    <div>
								                                       <div className="order-label">状态</div>  {getStatus(order.status)}
								                                    </div>
								                                    <div>
								                                        <a className="order-label" onClick={async () => {
														                                        const res = await client.get(orders[ind].imageURL);
														                                        orders[ind].imageSrc = res.content.toString();
														                                        showOrderDetail(orders[ind])
														                                    }}>查看明细</a> {order.plus}
								                                    </div>
								                                </div>
								                          </div>
								                      )
					                })}
		              </div>

		             <div className={`order-wrapper ${showDetail ? "show-detail" :"hide-detail"}`}>
		              <div className="order-detail">
		                  <img className="detail-nav" src="/detail-nav.svg" onClick={() => setShowDetail(false)}/>
		              </div>
		                  <div style={{paddingTop: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", paddingBottom: "60px"}}>
		                  <img src={orderDetail.imageSrc} className={"upload-demo"}  />
		                  <div style={{paddingLeft: "70px"}}>
		                      <div className="reminder" style={{marginTop: "20px"}}>
		                          姓名：
		                          {orderDetail.name}
		                      </div>
		                      <div className="reminder">
		                          证件号码：
		                          {orderDetail.id_no}
		                      </div>
		                      <div className="reminder">
		                          电话号码：
		                          {orderDetail.phone}
		                      </div>
		                      <div className="reminder">学校名称/代码：{orderDetail.school_code} | {orderDetail.school_name}</div>
		                      <div className="reminder">所在校别：{orderDetail.xiaobie}</div>
		                      <div className="reminder">学历类别：{orderDetail.xueli_type}</div>
		                      <div className="reminder">邮寄地址：{orderDetail.address}</div>
		                      <div className="reminder">订单价格：{orderDetail.prepaid ? "0.00元（学校已支付）" : "15.0元"}</div>
		                      {orderDetail.status === "rejected" && (
					                            <div className="reminder">审核失败原因：{orderDetail.notes}</div>
					                        )}
		                      <div>
		                      {  (orderDetail.status === "delivered" || orderDetail.status === "checked" || orderDetail.status === "finished") && (
					                            <div className="order-btn" onClick={() => plusOrder(orderDetail)} style={{marginLeft: "0px", marginRight: "20px"}}>
					                                加洗
					                            </div>
					                        ) }
		                      {  orderDetail.status === "rejected"  &&  (
					                            <div className="order-btn" style={{marginLeft: "0px", marginRight: "20px"}} onClick={() => setShowUpdatePhotoModal(true)}>
					                                重新上传照片
					                            </div>
					                        )}
		                      {  orderDetail.status === "pending"  &&  (
					                                <div className="order-btn" style={{marginLeft: "0px", marginRight: "20px"}} onClick={() => confirmPayment()}>
					                                    支付
					                                </div>
					                        )}
		                      <div className="order-btn" onClick={() => setShowDetail(false)} style={{marginLeft: "0px"}}>
		                          返回
		                      </div>
		                      </div>
		                  </div>
		                  </div>
		             </div>
		            </div>
		        )
}
